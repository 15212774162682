<template>
  <div>
    <b-row>
      <b-col
        md="6"
        sm="12"
        class="mb-2"
      >
        <div v-if="items.length < 1">
          Não há itens no carrinho :(
        </div>
        <b-card
          v-for="(item, index) in items"
          :key="item.id"
          :img-src="getImage(item.course_id, item.congress_id, item.img)"
          img-alt="Card image cap"
          img-left
          :title="item.name"
          style="height: 130px; margin-botton: 5px;box-shadow: 1px 2px #ccc;border: 1px solid #ccc;"
        >
          <b-card-text>
            <!-- Make a mask for this price: 100,00 -->
            R$: {{ item.value }}
            <b-button
              variant="danger"
              class="btn-icon float-right"
              @click="removeItem(items[index])"
            >
              Remover
              <feather-icon
                icon="TrashIcon"
                size="18"
              />
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card
          v-if="items.length > 0"
          class="mb-4"
        >
          <h4>Detalhes do pedido</h4>
          <b-card-text
            v-for="(item, index) in items"
            :key="item.id"
          >
            {{ index + 1 }} - {{ item.name }}: R$: {{ item.value }} - x{{ item.amount }}
          </b-card-text>
          <b-card-text>
            Total: R$: {{ calcTotalValue(items) }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      class="float-right"
      :disabled="items.length === 0 ? true : false"
      @click="$emit('next-step')"
    >
      Continuar
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
  },
  props: {},
  data() {
    return {
      base: null
    }
  },
  computed: {
    items() {
      return this.$store.state.payment.items
    }
  },
  mounted() {
    if (localStorage.getItem('items')) {
      this.ActionSetItems(JSON.parse(localStorage.getItem('items')))
    }
    this.base = process.env.VUE_APP_API_BASE
  },
  methods: {
    ...mapActions('payment', [
      'ActionSetItems',
      'ActionRemoveItem',
    ]),
    // [{"id": 1, "name": "Product 1", "value": 150, "amount": 2},{"id": 2, "name": "Product 2", "value": 50, "amount": 2}]
    async removeItem(item) {
      await this.ActionRemoveItem(this.items.indexOf(item))
      localStorage.setItem('items', JSON.stringify(this.items))
    },
    calcTotalValue(array) {
      this.total = 0
      if (array.length > 0) {
        array.forEach(element => {
          this.total = (element.amount * element.value) + this.total
        })
      }

      return this.total
    },
    getImage(course, congress, img) {
      if (course && img) {
        return `${this.base}/storage/course/thumb/${img}`
      } if (congress && img) {
        return `${this.base}/storage/congress/image/${img}`
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/eye.png')
    }
  }
}
</script>

<style scoped>
button.btn-next {
  display: none !important;
  color: #000 !important;
}
</style>
